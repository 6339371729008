import React, { useState } from 'react';
import SEO from '../../components/SEO';

import imageIllustrationMortageFirstSectionMobile from '../../assets/images/illustrations/RemortgagePage/remortgage-first-section-mobile.png';
import imageIllustrationMortageFirstSection from '../../assets/images/illustrations/RemortgagePage/variable-mortgage-first.png';


import { default as imageSecondSliderFirst, default as imageSecondSliderFourth, default as imageSecondSliderSecond, default as imageSecondSliderThird } from "../../assets/images/illustrations/home-save-money-banner.png";

import { default as imageSecondSliderFirstMobile, default as imageSecondSliderFourthMobile, default as imageSecondSliderSecondMobile, default as imageSecondSliderThirdMobile } from "../../assets/images/illustrations/home-save-money-banner.png";



import loadable from '@loadable/component';
import { useMemo } from 'react';
import CustomHeadingSection from '../../components/CommonSections/CustomHeadingSection';
import HeaderLeftContent from '../../components/CommonSections/CustomHeadingSection/HeaderLeftContent';
import SectionWrapper from '../../components/CommonSections/SectionWrapper';
import FAQSection from '../../components/FAQSection';
import CustomImage from '../../components/Image/Image';
import useSliderHeight from '../../hooks/useSliderHeight';
import useWindow from '../../hooks/useWindow';

const ModalsFlow = loadable(() => import("../../components/Modals/ModalsFlow"))
const MortgageDetailsSection = loadable(() => import("../../components/CommonSections/MortgageDetails"))
const FullSizeSliderSectionMid = loadable(() => import("../../components/CommonSections/FullSizeSliderSectionMid"))
const SliderSection = loadable(() => import("../../components/CommonSections/SliderSection"))
const DownloadAppSection = loadable(() => import("../../components/CommonSections/DownloadAppSection"))
const ContactWidget = loadable(() => import("../../components/ContactWidget"))

const IS_MOBILE = () => (typeof window !== 'undefined' ? window.innerWidth <= 767.98 : 0);
const IS_TABLET = () => (typeof window !== 'undefined' ? window.innerWidth <= 991.98 : 0);





const FAQItems = [
  {
    title: 'How often does the interest rate on a tracker mortgage change?',
    description: (
      <>
        The interest rate on a tracker mortgage will depend on the terms specified in the mortgage agreement. But in theory, a tracker mortgage can change each month because it is linked to the Bank of England base rate. However, it is rare to change every month.
      </>
    ),
  },
  {
    title: 'What happens if the base rate increases significantly?',
    description: (
      <>
      If you're on a tracker mortgage and the Bank of England base rate increases, you'll see an impact on your monthly mortgage repayments if the base rate increases, so will your mortgage interest rate.
      <br />
      <br />
      If your deal has a cap on maximum interest then you'll be protected to a certain extent so it's best to chat with your lender about the terms of your mortgage.
      </>
    ),
  },
  {
    title: 'Can I make overpayments on a tracker mortgage?',
    description: (
      <>
        Yes, you can make overpayments on a tracker mortgage and usually, you can overpay up to 20% of the remaining balance per annum.
        <br />
        <br />
        It's important to note that if you go over the agreed overpayments threshold set out in your mortgage term you'll likely incur a fee.
      </>
    ),
  },
  {
    title: 'Who might benefit from a tracker mortgage?',
    description: (
      <>
      Tracker mortgages can be attractive for those who expect interest rates to remain relatively stable or decline over the short to medium term.
      <br />
      <br />
      They can be especially beneficial during periods of low-interest rates, as borrowers can take advantage of lower payments. Tracker mortgages might also appeal to those who prefer to avoid the commitment of a fixed-rate mortgage and are comfortable with the potential fluctuations in monthly payments based on market conditions.
      </>
    ),
  },
  {
    title: 'Are there any limits to how much the interest rate can change in a tracker mortgage?',
    description: (
      <>
      Yes, most tracker mortgages come with "collars" and "caps" that limit how much the interest rate can change. A collar refers to the minimum interest rate that the tracker mortgage can reach, ensuring that the rate doesn't fall below a certain level during the term. The "cap" refers to the maximum.
      </>
    ),
  },
  {
    title: 'How does a tracker mortgage differ from a fixed-rate mortgage?',
    description: (
      <>
      A tracker mortgage has a variable interest rate and follows changes in the Bank of England rates set.
      <br />
      <br />
      A fixed-rate deal offers a stable interest rate that remains the same for the initial deal period, regardless of fluctuations in the broader interest rate market.
      <br />
      <br />
      Tracker mortgages provide more flexibility but carry the risk of potential interest rate fluctuations, whereas fixed-rate mortgages offer rate stability and predictability.
      </>
    ),
  },
  {
    title: 'Can I switch to a fixed-rate mortgage from a tracker mortgage later on?',
    description: (
      <>
      In most cases, you have the option to switch from a tracker mortgage to a fixed-rate deal at a later date by remortgaging.
      <br />
      <br />
      However, if you are remortgaging to a new fixed-mortgage before the end of your existing deal has come to an end it's worth considering any potential fees or penalties, and how a fixed-rate aligns with your long-term financial goals.
      </>
    ),
  },
  {
    title: 'How long can I get a tracker mortgage for?',
    description: (
      <>
      The term length for a tracker mortgage can vary from lender to lender and depends on what your personal circumstances and preferences are.
      <br />
      <br />
      Common tracker mortgage terms are often between 2 and 5 years, but there are also longer-term options available.
      <br />
      <br />
      It might be a good idea to agree to a shorter term if you anticipate changing market conditions or plan to remortgage in the near future.
      </>
    ),
  },
  {
    title: 'How do I know if a tracker mortgage is the right choice for me?',
    description: (
      <>
      Choosing the right mortgage, including a tracker mortgage, requires careful consideration of your financial goals, risk tolerance, and long-term plans.
      <br />
      <br />
      If you value flexibility and anticipate favourable interest rate movements, a tracker mortgage might be suitable.
      <br />
      <br />
      However, if you're risk-averse and value stability a fixed-rate mortgage could be a better fit for you. It's advisable to research compare different mortgage products and consult with a mortgage advisor to make an informed decision based on your personal circumstances and future plans.
      </>
    ),
  },
  {
    title: 'What are the early repayment charges on a tracker mortgage?',
    description: (
      <>
      Sometimes, the Early Repayment Charge (ERC) is more affordable on a tracker mortgage compared to other types of mortgages. This means that being on a tracker can give you more flexibility to move home or change your mortgage early without incurring a fee.
      <br />
      <br />
      However, it’s important to check your mortgage agreement documents to make sure you’re aware of what ERC’s you may incur if you were to remortgage early within your deal period.
      </>
    ),
  },
];

function TrackerRatePage() {
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [dealsContactModal, setDealsContactModal] = useState(true)
  const { isDesktop, isMobile } = useWindow();
  const [isTablet, setTablet] = useState(false);
  const sliderHeight = useSliderHeight("611px", "198px", "234px")

  const secondSliderItems = useMemo(() => ([
    {
      image: (isMobile || isTablet) ? imageSecondSliderFirstMobile : imageSecondSliderFirst,
      title: "All you need to know about tracker mortgages?",
      firstDescription: "A tracker mortgage is a type of variable-rate mortgage.",
      secondDescription: (
        <>
        They follow the base rate set by the Bank of England, so can be unpredictable and can change your monthly repayments.<br /><br />
        Tracker mortgages differ from a Standard Variable Rate (SVR) mortgage, which is the interest rates set by the lender. Although a tracker mortgage is usually cheaper than an SVR mortgage, and more predictable, this isn't guaranteed.
        </>
      )
    },
    {
      image: (isMobile || isTablet) ? imageSecondSliderSecondMobile : imageSecondSliderSecond,
      title: "What are the pros and cons of tracker mortgages?",
      secondDescription: (
        <>
          <b>Pros:</b><br /><br />
          If interest rates fall, you won't be locked at a high rate as the rate will also decrease, unlike with fixed rate deals.<br /><br />
          The lender cannot increase the rate on your deal by more than the Bank of England base rate rises.<br /><br />
          Some tracker mortgages are capped so your mortgage repayments can’t rise above a certain amount, even if the base rate continues to rise.<br /><br />
          Some interest rates may fall even further if there isn't a collar associated with the mortgage. (A collar is the minimum rate that’s set against your deal).<br /><br />
          Sometimes, the Early Repayment Charge (ERC) is more affordable on a tracker mortgage compared to other types of mortgages.<br /><br />
          Tracker rate mortgages also offer some transparency. You’ll have certainty that your mortgage repayments will only change if the tracked economic indicator changes, unline with a discount mortgage, where the lender sets the rate.
          <b>Cons:</b> <br /><br />
          If you choose to be on a capped tracker mortgage, the initial interest rate will likely be higher.<br /><br />
          It can be more difficult to budget as you don’t know for certain how much your mortgage repayment will be each month ahead.<br /><br />
          Unlike with fixed-rate deals, your monthly repayments can change.<br /><br />
          If an uncapped tracker mortgage is chosen, there is no limit to how much your repayments increase.<br /><br />
        </>
      )
    },
    {
      image: (isMobile || isTablet) ? imageSecondSliderThirdMobile : imageSecondSliderThird,
      title:"When should I go for a tracker mortgage?",
      firstDescription: "Despite tracker mortgages being one of the cheapest. Like discount mortgages, they are also one of the most uncertain and hard to predict so it's important to do your research to ensure you're making the right decision.",
      secondDescription: (
      <>
      A tracker mortgage may be a good decision when you're confident that you can afford the cost of monthly payments if they were to potentially increase.<br /><br />
      If interest rates are low then you may benefit from taking out a tracker mortgage. Or if you predict that they are likely to fall in the near future then a tracker mortgage would be favourable. However. it's difficult to predict interest rates so you will be open to risk of your payments increasing.
      </>
      ),
    },
    {
      image: (isMobile || isTablet) ? imageSecondSliderFourthMobile : imageSecondSliderFourth,
      title: "Can I switch from a tracker mortgage to a fixed mortgage?",
      firstDescription: "If your mortgage rate increases, you may decide that you want to remortgage to a fixed-rate mortgage. It's usually easier to switch from a tracker mortgage to a fixed rather than the other way around.",
      secondDescription: (
        <>
        You may still incur a penalty for exiting your deal during the introductory rate period, however it's usually not as high as exiting from a fixed-rate mortgage.<br /><br />
        Tracker mortgage deals are usually agreed on for a set period of 2, 3 or 5 years so it's best to assess what works best for you.
        </>
      )
    },
  ]), [isMobile, isTablet]);


  return (
    <>
      <SEO title="Remortgage Page" />
      <div className="common-page remortgage-page">
        <div className="remortgage-page__heading-container">
          <SectionWrapper isFirstSection={true}>
            <CustomHeadingSection
              leftContent={
                <HeaderLeftContent
                  title={
                    <>
                      What is a tracker
                      {isDesktop ? <br /> : ' '}
                      mortgage?
                    </>
                  }
                  image={isMobile ? imageIllustrationMortageFirstSectionMobile : null}
                  imageReversed
                  description="In just a few clicks we can help you find personalised tracker mortgage deals across the market."
                  buttonText="Compare Deals"
                  hashUrlOnClick="/mortgages/remortgage#mortgage-details"
                  useWidget
                />
              }
            />
            {!isMobile && (
              <div className="main-image-container">
                <CustomImage
                    className="w-100 main-image"
                    src={imageIllustrationMortageFirstSection}
                    alt="main image"
                  />
              </div>
            )}
          </SectionWrapper>
        </div>
        <div style={{minWidth: "100vw", minHeight: isMobile ? "826px" : "1000px"}}>
          <FullSizeSliderSectionMid
            sliderItems={secondSliderItems}
          />
        </div>
        <FAQSection
          FAQItems={FAQItems}
          title={
            <>
              Have a question about discount mortgages?
              {isDesktop ? <br /> : ' '}
              Check out our FAQ’s
            </>
          }
        />
        <div style={{minHeight: isMobile ? "382px" : "429px"}}>
          <DownloadAppSection />
        </div>
        <ContactWidget
          handleClick={() => {
            setIsContactModalOpen(true)
            setDealsContactModal(false)
          }}
          title="Speak to a mortgage expert for FREE"
        />
        <ModalsFlow
          isFirstOpened={isContactModalOpen}
          setIsFirstOpened={(state) => setIsContactModalOpen(state)}
          dealsContactModal={dealsContactModal}
        />
      </div>
    </>
  );
}



export default TrackerRatePage;
